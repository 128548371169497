import React from "react"
import Page from "../components/Templates/Page"

const OverdraftCoverage = ({ pageContext, ...props }) => {
  const context = {
    title: "Overdraft Coverage Options",
    slug: "overdraft-coverage",
  }

  return (
    <Page pageContext={context} {...props}>
      <h2 className="text-2xl mt-6 pb-2 border-b border-gray-200 mb-4 font-semibold text-gray-900">
        Overdraft Privilege And Overdraft Protection
      </h2>
      <p className="text-lg mb-2 text-gray-900 leading-loose">
        Valley Credit Union understands that unexpected overdrafts occur from
        time to time - Overdraft Coverage can help.
      </p>
      <h2 className="text-2xl mt-6 pb-2 border-b border-gray-200 mb-4 font-semibold text-gray-900">
        Overdraft Coverage Options
      </h2>
      <p className="text-lg text-gray-900 leading-loose">
        The choice is yours. Consider these ways to cover overdrafts:
      </p>
      <div className="flex flex-col mb-6">
        <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg border-gray-200"></div>
          <table className="border">
            <thead>
              <tr>
                <th className="border px-6 py-3 bg-gray-50 text-left text-lg leading-4 font-medium text-gray-500 tracking-wider">
                  Service
                </th>
                <th className="border px-6 py-3 bg-gray-50 text-left text-lg leading-4 font-medium text-gray-500 tracking-wider">
                  Cost
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <td className="border px-6 py-4 text-lg leading-5 font-medium text-gray-900 leading-8">
                  Overdraft Protection (Link to Another Deposit Account you have
                  at Valley Credit Union)
                </td>
                <td className="border px-6 py-4 text-lg leading-5 font-medium text-gray-900 leading-8">
                  $0 fee per transfer
                </td>
              </tr>
              <tr>
                <td className="border px-6 py-4 text-lg leading-5 font-medium text-gray-900 leading-8">
                  Overdraft Privilege Standard or Extended
                </td>
                <td className="border px-6 py-4 text-lg leading-5 font-medium text-gray-900 leading-8">
                  $33 Overdraft Fee per item presented*
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <p className="text-lg mb-6 text-gray-900 leading-loose">
        <strong>
          *Per item presented means each time an item is presented, including
          representment.
        </strong>
      </p>
      <p className="text-md mb-6 text-gray-800 leading-loose">
        Call us at 256-381-4800, email us at valleycu@vcumail.net, or come by a
        branch to sign up for this service.
      </p>
      <hr className="my-6" />
      <p className="text-lg mb-6 text-gray-900 leading-loose">
        <strong>Overdraft Protection</strong> applies to only checks and ACH
        debits and may help prevent overdrafts by automatically transferring
        funds to your checking account from another account you may have at
        Valley Credit Union.
      </p>
      <p className="text-lg mb-6 text-gray-900 leading-loose">
        <strong>Overdraft Privilege</strong> allows you to overdraw your account
        up to the disclosed limit for a fee to pay a transaction. Even if you
        have overdraft protection, Overdraft Privilege is still available as
        secondary coverage if the other protection source is exhausted. Please
        review the “What Else You Should Know” section at the bottom of this
        page for other important information.
      </p>
      <hr className="my-6" />
      <div className="flex flex-col mb-6">
        <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg border-gray-200"></div>
          <table className="border">
            <thead>
              <tr>
                <th className="border px-6 py-3 bg-gray-50 text-left text-lg leading-4 font-medium text-gray-500 tracking-wider">
                  Transactions Covered with Overdraft Privilege
                </th>
                <th className="border px-6 py-3 bg-gray-50 text-left text-lg leading-4 font-medium text-gray-500 tracking-wider">
                  Standard Coverage (No action required)
                </th>
                <th className="border px-6 py-3 bg-gray-50 text-left text-lg leading-4 font-medium text-gray-500 tracking-wider">
                  Extended Coverage (Your consent required on consumer
                  accounts)*
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <td className="border px-6 py-4 whitespace-no-wrap text-lg leading-5 font-medium text-gray-900">
                  Checks
                </td>
                <td className="border px-6 py-4 whitespace-no-wrap text-lg leading-5 font-medium text-gray-900 text-center font-bold">
                  ✕
                </td>
                <td className="border px-6 py-4 whitespace-no-wrap text-lg leading-5 font-medium text-gray-900 text-center font-bold">
                  ✕
                </td>
              </tr>
              <tr>
                <td className="border px-6 py-4 whitespace-no-wrap text-lg leading-5 font-medium text-gray-900">
                  ACH - Automatic Debits
                </td>
                <td className="border px-6 py-4 whitespace-no-wrap text-lg leading-5 font-medium text-gray-900 text-center font-bold">
                  ✕
                </td>
                <td className="border px-6 py-4 whitespace-no-wrap text-lg leading-5 font-medium text-gray-900 text-center font-bold">
                  ✕
                </td>
              </tr>
              <tr>
                <td className="border px-6 py-4 whitespace-no-wrap text-lg leading-5 font-medium text-gray-900">
                  Recurring Debit Card Payments
                </td>
                <td className="border px-6 py-4 whitespace-no-wrap text-lg leading-5 text-gray-900 text-center font-bold">
                  ✕
                </td>
                <td className="border px-6 py-4 whitespace-no-wrap text-lg leading-5 text-gray-900 text-center font-bold">
                  ✕
                </td>
              </tr>
              <tr>
                <td className="border px-6 py-4 whitespace-no-wrap text-lg leading-5 font-medium text-gray-900">
                  Online Bill Pay Items
                </td>
                <td className="border px-6 py-4 whitespace-no-wrap text-lg leading-5 text-gray-900 text-center font-bold">
                  ✕
                </td>
                <td className="border px-6 py-4 whitespace-no-wrap text-lg leading-5 text-gray-900 text-center font-bold">
                  ✕
                </td>
              </tr>
              <tr>
                <td className="border px-6 py-4 whitespace-no-wrap text-lg leading-5 font-medium text-gray-900">
                  Teller Window Transactions
                </td>
                <td className="border px-6 py-4 whitespace-no-wrap text-lg leading-5 text-gray-900 text-center font-bold">
                  ✕
                </td>
                <td className="border px-6 py-4 whitespace-no-wrap text-lg leading-5 text-gray-900 text-center font-bold">
                  ✕
                </td>
              </tr>
              <tr>
                <td className="border px-6 py-4 whitespace-no-wrap text-lg leading-5 font-medium text-gray-900">
                  ATM Withdrawals
                </td>
                <td className="border px-6 py-4 whitespace-no-wrap text-lg leading-5 font-medium text-gray-900">
                  <span className="screen-reader">Not covered</span>
                </td>
                <td className="border px-6 py-4 whitespace-no-wrap text-lg leading-5 text-gray-900 text-center font-bold">
                  ✕*
                </td>
              </tr>
              <tr>
                <td className="border px-6 py-4 whitespace-no-wrap text-lg leading-5 font-medium text-gray-900">
                  Everyday Debit Card Transactions
                </td>
                <td className="border px-6 py-4 whitespace-no-wrap text-lg leading-5 font-medium text-gray-900">
                  <span className="screen-reader">Not covered</span>
                </td>
                <td className="border px-6 py-4 whitespace-no-wrap text-lg leading-5 text-gray-900 text-center font-bold">
                  ✕*
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <p className="text-lg mb-6 text-gray-900 leading-loose">
        <strong>*</strong>If you choose Extended Coverage on your consumer
        account, ATM transactions and everyday debit card transactions will be
        included with the transactions listed under Standard Coverage. If you
        already have Overdraft Privilege Extended Coverage, it is not necessary
        to request it again. Business accounts automatically have Extended
        Coverage.
      </p>
      <a
        className="text-xl underline inline-block mb-6"
        href="/valley-credit-union-consent-form.pdf"
        target="_blank"
        rel="noreferrer"
      >
        Click Here For Extended Coverage Consent Form
      </a>
      <p className="text-lg mb-6 text-gray-900 leading-loose">
        You can discontinue the Overdraft Privilege in its entirety by
        contacting us at 256-381-4800 or sending us an email at
        valleycu@vcumail.net.
      </p>
      <div id="what-else-you-should-know"></div>
      <h2 className="text-2xl mt-6 pb-2 border-b border-gray-200 mb-4 font-semibold text-gray-900">
        What Else You Should Know
      </h2>
      <ul className="list-disc ml-10 mb-8">
        <li className="mb-4 leading-8 text-lg">
          A link to another account may be less expensive than an overdraft. A
          single larger overdraft will result in one fee, instead of multiple
          smaller overdrafts. Good account management is the best way to avoid
          overdrafts. Use our mobile, internet, and telephone banking services
          to track your balance. For financial education resources, please visit
          www.mymoney.gov or our website at www.valleycreditunion.net.
        </li>
        <li className="mb-4 leading-8 text-lg">
          The $33 Overdraft Fee that is charged if you overdraw your account is
          the same fee that is charged if an item is returned as unpaid. If
          multiple items overdraw your account on the same day, each item will
          be assessed an appropriate Overdraft Fee or a NSF Charge of $33. All
          fees and charges will be included as part of the Overdraft Privilege
          limit amount. Your account may become overdrawn more than the
          Overdraft Privilege limit amount because of a fee.
        </li>
        <li className="mb-4 leading-8 text-lg">
          Recipients of federal or state benefits payments who do not wish us to
          deduct the amount overdrawn and the Overdraft Fee from funds that you
          deposit or that are deposited into your account may call us at
          256-381-4800 to discontinue Overdraft Privilege.
        </li>
        <li className="mb-4 leading-8 text-lg">
          If an item is returned because the Available Balance (as defined
          below) in your account is not sufficient to cover the item and the
          item is presented for payment again, Valley Credit Union (“We”) will
          assess an NSF Charge each time it returns the item because it exceeds
          the Available Balance in your account. Because we may charge a NSF
          Charge each time an item is presented, we may charge you more than one
          fee for any given item as a result of a returned item and
          representment of the item. When we charge a NSF Charge , the charge
          reduces the Available Balance in your account and may put your account
          into (or further into) overdraft. If, on representment of the item,
          the Available Balance in your account is sufficient to cover the item
          we may pay the item, and, if payment causes an overdraft, charge an
          Overdraft Fee. We may use the terms “item” and “transaction”
          interchangeably.
        </li>
        <li className="mb-4 leading-8 text-lg">
          For consumer accounts, there is no limit on the total Overdraft Fees
          per day we will charge. These exceptions do not apply to business
          accounts.
        </li>
        <li className="mb-4 leading-8 text-lg">
          We generally post items in the following sequence. ACH credits post
          several times throughout the day as files are received. ACH debits are
          posted smallest to largest dollar amount; online bill pay items post
          from smallest to largest dollar amount; ATM, point of sale, and debit
          card transactions are posted in order received; paper checks are
          posted from lowest to highest dollar amount; however, because of the
          many ways we allow you to access your account, the posting order of
          individual items may differ from this sequence. Holds on funds
          (described herein) and the order in which transactions are posted may
          impact the total amount of Overdraft Fees or NSF Charges assessed.
        </li>
        <li className="mb-4 leading-8 text-lg">
          We may be obligated to pay some debit card transactions that are not
          authorized through the payment system but which we are required to pay
          due to the payment system rules, and as a result you may incur fees if
          such transactions overdraw your account. However, we will not
          authorize debit card or ATM transactions unless your account’s
          Available Balance (including Overdraft Coverage Options) is sufficient
          to cover the transactions and any fee(s).
        </li>
        <li className="mb-4 leading-8 text-lg">
          Giving us your consent to pay everyday debit card and ATM overdrafts
          on your consumer account (Extended Coverage) may result in you
          incurring Overdraft Fee s for transactions that we would otherwise be
          required to pay without assessing an Overdraft Fee. However, this
          would allow us to authorize transactions up to the amount of your
          Overdraft Privilege limit. If you consent to Extended Coverage on your
          consumer account, it will remain on your account until it is otherwise
          withdrawn.
        </li>
        <li className="mb-4 leading-8 text-lg">
          Overdraft Privilege is not a line of credit; it is a discretionary
          overdraft service that can be withdrawn at any time without prior
          notice.
        </li>
        <li className="mb-4 leading-8 text-lg">
          Depositor and each Authorized Signatory will continue to be liable,
          jointly and severally, for all overdraft and fee amounts, as described
          in the Membership and Account Agreement. The total (negative) balance,
          including all fees and charges, is due and payable upon demand.
        </li>
      </ul>
      <h2 className="text-2xl mt-6 pb-2 border-b border-gray-200 mb-4 font-semibold text-gray-900">
        Understanding your Available Balance:
      </h2>
      <p className="text-lg mb-2 text-gray-900 leading-loose">
        Your account has two kinds of balances: the Ledger Balance and the
        Available Balance.
      </p>
      <ul className="list-disc ml-10 mb-8">
        <li className="mb-4 leading-8 text-lg">
          We authorize and pay transactions using the Available Balance.
        </li>
        <li className="mb-4 leading-8 text-lg">
          Your Ledger Balance reflects the full amount of all deposits to your
          account as well as payment transactions that have been posted to your
          account. It does not reflect checks you have written and are still
          outstanding or transactions that have been authorized but are still
          pending.
        </li>
        <li className="mb-4 leading-8 text-lg">
          Your Available Balance is the amount available to you to use for
          purchases, withdrawals, or to cover transactions. The Available
          Balance is your Ledger Balance, less any holds due to pending debit
          card transactions and holds on deposited funds.
        </li>
        <li className="mb-4 leading-8 text-lg">
          The balance used for authorizing checks, ACH items, and recurring
          debit card transactions is your Available Balance plus the amount of
          the Overdraft Privilege limit and any available Overdraft Protection.
        </li>
        <li className="mb-4 leading-8 text-lg">
          The balance used for authorizing ATM and everyday debit card
          transactions on accounts with Standard Coverage is your Available
          Balance plus any available Overdraft Protection but does NOT include
          the Overdraft Privilege limit.
        </li>
        <li className="mb-4 leading-8 text-lg">
          The balance used for authorizing ATM and everyday debit card
          transactions on accounts with Extended Coverage is your Available
          Balance plus any available Overdraft Protection and includes the
          Overdraft Privilege limit.
        </li>
        <li className="mb-4 leading-8 text-lg">
          Because your Available Balance reflects pending transactions and debit
          holds, your balance may appear to cover a transaction but later upon
          settlement it may not be sufficient to cover such transaction. In such
          cases, the transaction may further overdraw your account and be
          subject to additional overdraft fees. You should assume that any item
          which would overdraw your account based on your Available Balance may
          create an overdraft. Note that we may place a hold on deposited funds
          in accordance with our Deposit Account Agreement and Disclosure, which
          will reduce the amount in your Available Balance.
        </li>
        <li className="mb-4 leading-8 text-lg">
          Please be aware that the Overdraft Privilege amount is not included in
          your Available Balance provided through online banking, mobile banking
          or Valley Credit Union’s ATMs.
        </li>
        <li className="mb-4 leading-8 text-lg">
          We will place a hold on your account for any authorized debit card
          transaction until the transaction settles (usually within two business
          days) or as permitted by payment system rules. In some cases, the hold
          may exceed the amount of the transaction. When the hold ends, the
          funds will be added to the Available Balance in your account. If your
          account is overdrawn after the held funds are added to the Available
          Balance and the transaction is posted to the Available Balance, an
          Overdraft Fee may be assessed.
        </li>
        <li className="mb-4 leading-8 text-lg">
          Except as described herein, we will not pay items if the Available
          Balance in your account (including the Overdraft Privilege limit, if
          applicable) is not sufficient to cover the item(s) and the amount of
          any fee(s).
        </li>
      </ul>
      <h2 className="text-2xl mt-6 pb-2 border-b border-gray-200 mb-4 font-semibold text-gray-900">
        Understanding Overdraft Privilege Limits
      </h2>
      <ul className="list-disc ml-10 mb-8">
        <li className="mb-4 leading-8 text-lg">
          New eligible checking accounts will receive a $100 Introductory
          Overdraft Privilege limit at account opening that will be increased to
          $500 after 60 days in good standing.
        </li>
        <li className="mb-4 leading-8 text-lg">
          Overdraft Privilege may be reduced if you default on any loan or other
          obligation to us, your account becomes subject to any legal or
          administrative order or levy, or if you fail to maintain your account
          in good standing by not bringing your account to a positive balance
          within 32 days for a minimum of one business day. You must bring your
          account balance positive for at least one business day to have the
          full Overdraft Privilege limit reinstated.
        </li>
      </ul>
      <hr className="my-6" />
      <p className="text-lg mb-6 text-gray-900 leading-loose">
        If you have any questions about Overdraft Protection or Overdraft
        Privilege, please call us at 256-381-4800 or visit a branch.
      </p>
      <p className="text-lg mb-6 text-gray-900 leading-loose">
        This page contains links to PDF documents for which Adobe Acrobat Reader
        is required to view.
        <br />
        <a
          className="underline"
          target="_blank"
          href="https://get.adobe.com/reader/otherversions/"
          rel="noreferrer"
        >
          Download Free Adobe Acrobat Reader
        </a>
      </p>
    </Page>
  )
}

export default OverdraftCoverage;
